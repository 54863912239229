import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Form } from '../components/form'
import BlockText from '../components/block-text'

interface JobData {
  title: {
    text: string
  }
  link: string
  pdf: {
    asset: {
      url: string
      description: string
    }
  }
}

const Careers = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityCareers {
        id
        title
        _rawCareersText
        hiring
        jobs_heading
        jobs {
          title
          pdf {
            asset {
              url
              description
            }
          }
          link
        }
        form_heading
      }
      sanityContact {
        referral_source {
          source
        }
      }
    }
  `)

  const {
    title,
    _rawCareersText,
    hiring,
    jobs_heading,
    jobs,
    form_heading,
  } = data.sanityCareers

  const { referral_source } = data.sanityContact

  return (
    <Layout>
      <SEO title={title} />
      <div className="container-theme">
        <div className="careers-page container-fluid">
          <section className="careers-page-top row">
            <div className="top col-md-4 offset-3 col-sm-5 col-6-mob offset-0-mob">
              <h1 className="careers-page-title">{title}</h1>
              <BlockText
                className="careers-page-text"
                blocks={_rawCareersText}
              />
            </div>
          </section>
          <section className="careers-page-jobs row">
            {hiring && (
              <div className="jobs col-md-4 offset-3 col-sm-5 col-6-mob offset-0-mob">
                <h2 className="heading-std">{jobs_heading}</h2>
                {jobs.map((job: JobData, i: number) => {
                  return (
                    <a
                      key={i}
                      href={job.pdf ? job.pdf.asset.url : job.link}
                      target="_blank"
                      className="job-link"
                      style={{ display: 'block' }}
                    >
                      <h3>{job.title}</h3>
                    </a>
                  )
                })}
              </div>
            )}
          </section>
          <section className="careers-page-form row">
            <div className="contact-form col-md-4 offset-3 col-sm-5 col-6-mob offset-0-mob">
              <h2 className="heading-std">{form_heading}</h2>
              <Form name="careers" referral_source={referral_source} />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default Careers
